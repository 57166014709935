import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../utils/I18n';

import reviewBoards from "../../data/reviewBoards.json";

const ReviewBoards = ({lang}) => {
  const i18n = new I18n({ lang });
  const itemsDefault = reviewBoards.filter(it =>it["lang"] == "en");
  const items = reviewBoards.filter(it =>it["lang"] == lang);


  return (
    <div className="slds-container--center reviewBoards-content slds-m-top--xx-large slds-m-bottom--xx-large slds-p-top--large">
      <div className="reviewBoards_subtitle">
        {i18n.text('reviewBoards_title')}
      </div>
      <table className="reviewBoards-table">
        <thead>
          <tr>
            <th>{i18n.text('reviewBoards_city')}</th>
            <th>{i18n.text('reviewBoards_datesAvailable')}</th>
            <th>{i18n.text('reviewBoards_status')}</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={`content_item_${index}`}>
              <td className="reviewBoards_row">{item.title}</td>
              <td dangerouslySetInnerHTML={{__html: item.txt1}} className="reviewBoards_row"></td>
              <td className="reviewBoards_row">
                <div>{item.txt3}</div>
              </td>
            </tr>
          ))}
          {
            items.length == 0 && itemsDefault.map((item, index) => (
              <tr key={`content_item_${index}`}>
                <td className="reviewBoards_row">{item.title}</td>
                <td dangerouslySetInnerHTML={{__html: item.txt1}} className="reviewBoards_row"></td>
                <td className="reviewBoards_row">
                  <div>{item.txt3}</div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <div className="reviewBoards-footer">
        <a href="/credentials/architectreviewboard" target="_blank">{i18n.text('reviewBoards_ctaText')}</a>
      </div>
    </div>
  );
};

export default ReviewBoards;
