import React from 'react';
import I18n from '../utils/I18n';

const JoinCommunity = ({lang, slug}) => {
  const i18n = new I18n({ lang });

  return (
    <div>
      <div className="slds-container--medium slds-container--center slds-m-top--x-large">
        <div className="joinCommunity-box">
          <div className="slds-text-align--center slds-m-bottom--x-small Fz(24) Fw(b)">
            {i18n.text('joinTheCommunity_title')}
          </div>
          {slug=="strategydesigner" 
            ? <div className="slds-text-align--center Fz(18)" dangerouslySetInnerHTML={{__html: i18n.text('joinTheCommunity_description_strategydesigner')}} /> 
            : slug=="businessanalyst"
              ? <div className="slds-text-align--center Fz(18)" dangerouslySetInnerHTML={{__html: i18n.text('joinTheCommunity_description_businessanalyst')}} />
            : slug=="salesrepresentative"
              ? <div className="slds-text-align--center Fz(18)" dangerouslySetInnerHTML={{__html: i18n.text('joinTheCommunity_description_salesrepresentative')}} />
            : slug=="datacloudconsultant"  
              ? <div className="slds-text-align--center Fz(18)" dangerouslySetInnerHTML={{__html: i18n.text('joinTheCommunity_description_datacloudconsultant')}} />
            : <div className="slds-text-align--center Fz(18)" dangerouslySetInnerHTML={{__html: i18n.text('joinTheCommunity_description')}} />
            }
          
        </div>
      </div>
    </div>
  );
};

export default JoinCommunity;
