import React from 'react';
import Credentials from '../components/Credentials';
import I18n from '../utils/I18n';

const Prerequisites = ({data, text, lang, title}) => {
  const i18n = new I18n({ lang });
  return (
    <div>
      <div className="prerequisites-title slds-p-top--small">
        {i18n.text('prerequisites_title')}
      </div>
      {Boolean(text != '') && (title == 'B2B Solution Architect') && (
        <div dangerouslySetInnerHTML={{__html: text}} className="slds-container--medium slds-container--center slds-text-align--center slds-m-top--medium Fz(18) slds-container--center"></div>
      )}
      <Credentials names={data} lang={lang} />
      {Boolean(text != '') && (title != 'B2B Solution Architect') &&(
        <div dangerouslySetInnerHTML={{__html: text}} className="slds-container--medium slds-container--center slds-text-align--center slds-m-top--medium Fz(18) slds-container--center"></div>
      )}
    </div>
  );
};

export default Prerequisites;
