import React from 'react';
import I18n from '../utils/I18n';

const AboutExam = ({lang, customTitle, description, guide, customCta}) => {
  const i18n = new I18n({ lang });
  return (
    <div className="slds-container--center slds-container--medium slds-m-bottom--x-large slds-is--relative Pt(1px)">
      <div className="aboutExam-box H(100%) slds-is-relative">
        <div className="slds-text-align--center slds-m-bottom--x-small Fz(24) Ff(NeutrafaceDisplay-Titling)">
          {customTitle ? customTitle : i18n.text('aboutTheExam_title')}
        </div>

        <div className="Fz(18) slds-text-align--center">
          {description}<br />
          {
            guide != '' && guide != undefined ?
            <a className="aboutExam-link" href={guide}>{i18n.text('aboutTheExam_ctaText')}</a>
            :
            <></>
          }
          
          {customCta && Object.hasOwnProperty.call(customCta, 'label') && Object.hasOwnProperty.call(customCta, 'link') && (
            <a className="aboutExam-link" href={`/${lang}/${customCta.link}`}>{customCta.label}</a>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutExam;
