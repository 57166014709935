import React from 'react';
import PropTypes from 'prop-types';
import Translation from '../utils/Translation';
import trailmixes from "../../data/trailmixes.json";

const TrailMix = ({listToShow, lang}) => {
  const items = Translation.getItems(trailmixes, listToShow, 'slug', lang);

  return (
    <div className="slds-grid slds-wrap slds-grid--align-center slds-grid--pull-padded slds-grid--vertical-stretch">
      {items.map((item, index) => (
        <div className="slds-size--1-of-1 slds-medium-size--1-of-3 slds-p-around--medium" key={index}>
          <div className="trailMix-card">
            <div className="trailMix-card-content">
              <div className="trailMix-card-header clearfix">
                <img className="trailMix-card-header_icon" src={item.img} />
                <div className="trailMix-card-header_text">
                  {item.time}
                </div>
              </div>
              <div className="trailMix-card-body">
                <div>
                  <span>{item.type} -&nbsp;</span>
                  <span><a href={`https://trailhead.salesforce.com/${lang}/me/00550000006yDdKAAU`}>Salesforce Trailhead</a></span>
                </div>
                <div className="trailMix-card-body_title">
                  <a href={item.url}>{item.title}</a>
                </div>
                <div className="trailMix-card-body_text">
                  {item.text}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TrailMix;
