import React from 'react';

const SeparatorDots = () => {
    return (
      <div className="slds-m-top--large">
          <div className="certification-bubble" />
          <div className="certification-bubble" />
      </div>
    );
};

export default SeparatorDots;
