import React from 'react';
import Credentials from '../components/Credentials';

const EarnCredentialTemp = () => {

  return (
    <div className="slds-p-bottom--x-large">
        <div className="slds-text-align--center cert-earn_title slds-container--medium slds-container--center">
            <h1>REGISTRATION COMING SOON</h1>
        </div>
        <div className="slds-text-align--center cert-earn_subtitle slds-container--medium slds-container--center">
          Registration and delivery available May 4, 2023. <br/>
          Start preparing today with the Sales Representative Certification Exam Guide.  <br/>
        </div>
        <div className="slds-text-align--center slds-m-top--x-large">
              <a 
                className="slds-button slds-button_brand tds-button_x-large tds-button_brand" 
                href="https://trailhead.salesforce.com/help?article=Salesforce-Certified-Sales-Representative-Exam-Guide"
                target="_blank">
                START PREPARING
              </a>
            </div>
    </div>
  );
};

export default EarnCredentialTemp;
