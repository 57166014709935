import React from 'react';
import Trailmixes from '../components/Trailmixes';
import I18n from '../utils/I18n';

const StudyPrepare = ({data, text, lang}) => {
  const i18n = new I18n({ lang });

  return (
    <div>
      <div className="studyPrepare-title slds-m-top--small">
        {i18n.text('studyAndPrepare_title')}
      </div>

      <div className="slds-text-align--center Fz(18)">
        {i18n.text('studyAndPrepare_description')}
      </div>

      <div className="slds-container--center slds-container--x-large slds-p-top--large">
        <Trailmixes listToShow={data} lang={lang} />
      </div>

      {Boolean(text) && text.length > 0 && (
        <div className="slds-container--center slds-container--large slds-p-top--large">
          <div dangerouslySetInnerHTML={{__html: text}} className="slds-container--medium slds-container--center slds-text-align--center slds-m-top--small slds-m-bottom--medium Fz(18)"></div>
        </div>
      )}

    </div>
  );
};

export default StudyPrepare;
