import React from 'react';
import I18n from '../utils/I18n';

const LearnSkills = ({skills, lang}) => {
  const i18n = new I18n({ lang });

  return (
    <div>
      <div className="learnSkills-title slds-m-top--small">
        {i18n.text('learnTheSkills_title')}
      </div>
      <div className="slds-text-align--center slds-m-bottom--x-large Fz(18)">
        {i18n.text('learnTheSkills_subtitle')}
      </div>
      <div className="Maw(55rem) slds-container--center">
        <div className="learnSkills-box">
          <div className="slds-text-align--center slds-m-bottom--x-small Fz(24) Fw(b)">
            {i18n.text('learnTheSkills_classesAndWorkshops')}
          </div>
          <ul className="learnSkills-bullets Fz(18) Lh(1.5)">
            {skills.map((item, index) => {
              return (<li key={index}><a href={item.url}>{item.text}</a></li>);
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LearnSkills;
