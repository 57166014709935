import React from 'react';
import Credentials from '../components/Credentials';
import I18n from '../utils/I18n';

const EarnCredential = ({lang, data}) => {
  const i18n = new I18n({ lang });

  return (
    <div className="slds-p-bottom--x-large">
      <div className="slds-text-align--center cert-earn_title slds-container--medium slds-container--center">
        <h1>{i18n.text('earnCredential_title')}</h1>
      </div>

      {data && data.length > 0 && data.map((item, index) => {
        return (
          <>
            <div className="slds-text-align--center cert-earn_subtitle">
              {item.description}
            </div>

            <Credentials names={[item.credential]} style="gray" lang={lang} />

            <div className="slds-text-align--center slds-m-top--x-large">
              <a 
                className="slds-button slds-button_brand tds-button_x-large tds-button_brand" 
                href={i18n.text('earnCredential_ctaLink')}
                target="_blank">
                {item.ctaText}
              </a>
            </div>
          </>
        );
      })}

      {data.length == 0 && (
        <>
          <div className="slds-text-align--center cert-earn_subtitle">
            {i18n.text('earnCredential_subtitle')}
          </div>
          <div className="slds-text-align--center slds-m-top--x-large">
            <a
              className="slds-button slds-button_brand tds-button_x-large tds-button_brand"
              href={i18n.text('earnCredential_ctaLink')}
              target="_blank">
                {i18n.text('earnCredential_ctaText')}
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default EarnCredential;
